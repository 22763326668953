import React, { Component } from "react";
import { FaEnvelope } from "react-icons/fa";
import { FiChevronRight, FiMapPin, FiPhone } from "react-icons/fi";

export default class ContactSidebar extends Component {
  render() {
    return (
      <aside className="contact-sidebar">
        <h1>Our Office</h1>

        <div className="contact-sidebar-infos">
          <div className="item d-flex">
            <div className="icon">
              <span>
                <FiMapPin />
              </span>
            </div>
            <div className="details">
              <h3>Address</h3>
              <span>2600 Crystal Drive, Arlington, VA 22202</span>
            </div>
          </div>

          <div className="item d-flex">
            <div className="icon">
              <span>
                <FiPhone />
              </span>
            </div>
            <div className="details">
              <h3>Phone Number</h3>
              <span>(703) 434 - 9980</span>
            </div>
          </div>

          <div className="item d-flex">
            <div className="icon">
              <span>
                <FaEnvelope />
              </span>
            </div>
            <div className="details">
              <h3>Email</h3>
              <span>info@gov-logic.com</span>
            </div>
          </div>
        </div>

        <button className="theme-button" type="submit">
          Contact With Us <FiChevronRight className="icon" />
        </button>
      </aside>
    );
  }
}
