import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/owl.carousel.css";
import "../../assets/css/owl.theme.default.min.css";
import img6 from "../../assets/images/brands/attain.png";
import img5 from "../../assets/images/brands/dell.png";
import img2 from "../../assets/images/brands/gdit.png";
import img4 from "../../assets/images/brands/leidos.png";
import img1 from "../../assets/images/brands/mantech.png";
import img3 from "../../assets/images/brands/perspecta.png";

export default class Clients extends Component {
  clients = {
    sliders: [
      {
        img: img1,
      },
      {
        img: img2,
      },
      {
        img: img3,
      },
      {
        img: img6,
      },
      {
        img: img4,
      },
      {
        img: img5,
      },
    ],
  };
  state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  render() {
    return (
      <section
        className="clients-wrapper text-center"
        style={{ marginTop: "-3rem" }}
      >
        <div className="container">
          <OwlCarousel
            className="owl-theme"
            loop={true}
            autoplay={true}
            margin={10}
            dots={false}
            nav={false}
            responsive={this.state.responsive}
          >
            {this.clients.sliders.map((item, index) => {
              return (
                <div
                  className="items text-center"
                  key={index}
                  style={{ margin: "1rem" }}
                >
                  <img src={item.img} alt="Clients" />
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </section>
    );
  }
}