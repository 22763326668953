import React, { Component } from 'react';

export default class Cta extends Component {
    render() {
        return (
            <section className="cta-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-8">
                            <h1>This Year <strong>55 Companies</strong> Started & They Are in Business With Us.</h1>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
