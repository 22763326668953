import React, { Component } from 'react'
import Breadcrumb from '../components/common/Breadcrumb'
import Navbar from '../components/common/Navbar'
import CtaForm from '../components/other/CtaForm'
import Divider from '../components/other/Divider'
import Footer from '../components/other/Footer'
import CaptureDetailsContent from '../components/services/CaptureDetailsContent'

export default class CaptureDetailsPage extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Capture Intelligence" services="true" />
                <section className="service-details-wrapper">
                    <div className="container">
                        <div className="service-details-grid d-grid">
                            {/* <ServiceSidebar /> */}
                            <CaptureDetailsContent />
                        </div>
                    </div>
                </section>
                <Divider />
                <div className="form-white-bg">
                    <CtaForm />
                </div>
                <Footer />
            </>
        )
    }
}
