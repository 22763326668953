import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class FooterError extends Component {
  render() {
    return (
      <footer className="error-footer-wrapper d-flex">
        <div className="left">
          <p>
            {" "}
            Copyright &copy; {new Date().getFullYear()} GovLogic, LLC. All
            Rights Reserved.
          </p>
        </div>
        <div className="right">
          <ul className="pages d-flex">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}
