import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Divider from '../components/other/Divider'
import CtaForm from '../components/other/CtaForm'
// import ServiceSidebar from '../components/services/ServiceSidebar'
import DigitalServicesContent from '../components/services/DigitalServicesContent'
import Footer from '../components/other/Footer'

export default class DigitalServicesDetailsPage extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Strategic Consulting" services="true"/>
                <section className="service-details-wrapper">
                    <div className="container">
                        <div className="service-details-grid d-grid">
                            {/* <ServiceSidebar /> */}
                            <DigitalServicesContent />
                        </div>
                    </div>
                </section>
                <Divider />
                <div className="form-white-bg">
                    <CtaForm />
                </div>
                <Footer />
            </>
        )
    }
}
