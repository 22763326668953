import React, { Component } from "react";
import logo from "../../assets/images/logo/govlogic-blue.png";
import { Link } from "react-router-dom";

export default class HeaderError extends Component {
  render() {
    return (
      <div className="header-error-wrapper d-flex">
        <div className="left">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="right">
          <ul className="links d-flex">
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
