import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { FiTarget } from "react-icons/fi";
import { FaHandshake } from "react-icons/fa";
import { RiMapPin5Line } from "react-icons/ri";


export default class Service3clmns extends Component {
    servicestates = {
        items: [
            {
                icon: <FiTarget className="service-items-icon" />,
                title: 'Our Mission.',
                description: 'Our mission is to help companies identify, quality, capture, and pursue the right opportunities.'
            },
            {
                icon: <RiMapPin5Line className="service-items-icon" />,
                title: 'Our Vision.',
                description: 'It is our professional commitment to exceed our clients’ expectations to earn their trust, respect, and repeat business.'
            },
            {
                icon: <FaHandshake className="service-items-icon" />,
                title: 'Our Approach.',
                description: 'In addition to providing a variety of services, our focus is on the empathetic relationships we build with our clients, with an integrated approach that goes beyond just meeting demands and expectations.'
            }
        ]
    }
    render() {
        return (
            <section className="services-area service-3columns">
                <div className="container">
                    <div className="services-items-grid">
                        {
                            this.servicestates.items.map((item, index) => {
                                return <div className="service-items" key={index}>
                                    <span className="service-items-num">{index}</span>
                                    <span className="service-items-iconbox">{item.icon}</span>
                                    <Link to="/service-details">
                                        <h3 className="service-items-title">{item.title}</h3>
                                    </Link>
                                    <p className="service-items-description">{item.description}</p>
                                    {/* <Link to="/service-details" className="simple-button">Learn More <FiChevronRight className="icon" /></Link> */}
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}
