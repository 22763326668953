import $ from "jquery";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/govlogic-blue.png";
import logo2 from "../../assets/images/logo/govlogic-white.png";

export default function Navbar() {
  const [navOpen, setNavOpen] = useState(false);
  const [setScrolled] = useState(false);
  const [navLogo, setNavLogo] = useState(logo2);

  /*====  side-widget-menu  =====*/
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item",
    function () {
      $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass("active");
      $(this).toggleClass("active");
    }
  );
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item.active",
    function () {
      $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass(
        "active"
      );
    }
  );

  $(window).on("scroll", function () {
    // Header Menu Fixed
    if ($(window).scrollTop() > 5) {
      $("body").addClass("header-menu-fixed");
      $(".header-menu-wrapper").addClass("d-none");
      $(".scroll-d-block").addClass("d-block").removeClass("d-none");
      setScrolled(false);
      setNavLogo(logo);
    } else {
      $("body").removeClass("header-menu-fixed");
      $(".header-menu-wrapper").removeClass("d-none");
      $(".scroll-d-block").removeClass("d-block").addClass("d-none");
      setScrolled(true);
      setNavLogo(logo2);
    }
  });

  return (
    <>
      {/* <HeaderBar /> */}

      <div className="header-menu-wrapper">
        <div className="header-menu-container container">
          <div className="header-menu-flex">
            <div className="header-menu-left">
              <Link to="/">
                <img src={navLogo} alt="Logo" className="logo" />
              </Link>
            </div>
            <div className="header-menu-right">
              <nav>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                    <ul className="dropdown-menu-item">
                      <li>
                        <Link to="/strategy">Strategic Consulting</Link>
                      </li>
                      <li>
                        <Link to="/capture-intelligence">
                          Capture Intelligence
                        </Link>
                      </li>
                      <li>
                        <Link to="/proposal-management">
                          Proposal Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
              <div className="header-menu-right-flex">
                <div
                  className="side-menu-open"
                  onClick={() => setNavOpen(!navOpen)}
                >
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-menu-wrapper d-none scroll-d-block">
        <div className="header-menu-container container">
          <div className="header-menu-flex">
            <div className="header-menu-left">
              <a href="/">
                <img src={logo} alt="Logo" className="logo" />
              </a>
            </div>
            <div className="header-menu-right">
              <nav>
                <ul>
                  <li>
                    <a href="/" className="blue-header">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/about" className="blue-header">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="/services" className="blue-header">
                      Services
                    </a>
                    <ul className="dropdown-menu-item">
                      <li>
                        <Link to="/strategy">Strategic Consulting</Link>
                      </li>
                      <li>
                        <Link to="/capture-intelligence">
                          Capture Intelligence
                        </Link>
                      </li>
                      <li>
                        <Link to="/proposal-management">
                          Proposal Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/contact" className="blue-header">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="header-menu-right-flex">
                <div
                  className="side-menu-open"
                  onClick={() => setNavOpen(!navOpen)}
                >
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={navOpen ? "side-nav-container active" : "side-nav-container"}
      >
        <div className="hamburger-menu">
          <div
            className="hamburger-menu-lines side-menu-close"
            onClick={() => setNavOpen(!navOpen)}
          ></div>
        </div>
        <div className="side-menu-wrap">
          <a href="/" style={{ marginLeft: "20px" }}>
            <img src={logo2} alt="Logo" width={250} />
          </a>
          <ul className="side-menu-ul">
            <li className="sidenav__item">
              <Link to="/">home</Link>
            </li>
            <li className="sidenav__item">
              <Link to="/about">about</Link>
            </li>
            <li className="sidenav__item">
              <Link to="/services">services</Link>
              <span className="menu-plus-icon"></span>
              <ul className="side-sub-menu">
                <li>
                  <Link to="/strategy">strategic consulting</Link>
                </li>
                <li>
                  <Link to="/capture-intelligence">capture intelligence</Link>
                </li>
                <li>
                  <Link to="/proposal-management">proposal management</Link>
                </li>
              </ul>
            </li>
            <li className="sidenav__item">
              <Link to="/contact">contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
