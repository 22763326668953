import React, { Component } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import img4 from '../../assets/images/agile-2.jpeg';
import img3 from '../../assets/images/comprehensive.jpeg';
import img1 from '../../assets/images/experience.jpeg';
import img2 from '../../assets/images/partnership.jpeg';
import SectionsTitle from '../common/SectionsTitle';

export default class SimpleTab extends Component {
    tabstate = {
        tabs: [
            {
                img: img1,
                desc: "GovLogic delivers extensive experience and keys to success from all aspects of government contracting. Our SMEs are specialists within their respective fields and are dedicated to your company’s success.",         
            },
            {
                img: img2,
                desc: "At GovLogic, you are more than just a client. We are dedicated to building lifelong partnerships with our clients in order to achieve long term success.",
                img_position: "img_right"
            },
            {
                img: img3,
                desc: "Tired of working with different consultants across your pipeline? We are your one-stop partner for integrated services to augment and advance your business development, capture management, and proposal efforts. Our calibrated lifecycle solutions are built for cost optimization and to fit your organization’s specific requirements.",            
            },
            {
                img: img4,
                desc: "Our organizational structure and processes are built for a nimble and rapid service delivery. Your organization and requirements are dynamic, our agile approach to solving complex challenges focus on continuously integrating best practices and innovative solutions.",
                img_position: "img_right"
            }
        ]
    }

    tabsnav = {
        navs: [
            {
                title: "Experience"
            },
            {
                title: "Partnership"
            },
            {
                title: "Comprehensive Services"
            },
            {
                title: "Agile Delivery"
            }
        ]
    }
    render() {
        return (
            <section className="tabs-wrapper">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <SectionsTitle title="Why Choose Us." subtitle="Our Best Features" />
                        </div>
                    </div>


                    <div className="tabs-content-wrap">
                        <Tabs>
                            <TabList className="tabs-nav">
                                {this.tabsnav.navs.map((item, index) => {
                                    return <Tab key={index}>{item.title}</Tab>
                                })}
                            </TabList>
                            {this.tabstate.tabs.map((tab, index) => {
                                return <TabPanel className="tabs-content" key={index}>
                                    <div className={'tabs-content-flex ' + tab.img_position}>
                                        <div className="tabs-left">
                                            <img src={tab.img} alt="Tabs" className={tab.img_position}/>
                                        </div>
                                        <div className="tabs-right">
                                            <p className="tabs-desc">
                                                {tab.desc}
                                            </p>
                                            <Link to="/#" className="theme-button">{tab.button} <FiChevronRight className="icon" /></Link>
                                        </div>
                                    </div>
                                </TabPanel>
                            })}
                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}
