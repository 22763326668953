import React, { Component } from 'react'
import { FiTarget, FiUserCheck } from 'react-icons/fi'
import { TiLightbulb } from 'react-icons/ti'
import img1 from '../../assets/images/meeting-5.jpeg'
import img2 from '../../assets/images/planning-1.jpeg'
import img3 from '../../assets/images/planning-2.jpeg'
import SectionsTitle from '../common/SectionsTitle'
// import ModalVideo from 'react-modal-video'
// import Progressbar from '../other/Progressbar'

export default class About extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    aboutstate = {
        supports: [
            {
                icon: <FiUserCheck />,
                title: 'Customer Centric'
            },
            {
                icon: <FiTarget />,
                title: 'Mission Oriented'
            },
            {
                icon: <TiLightbulb />,
                title: 'Innovative'
            }
        ]
    }
    aboutrightimg = {
        images: [
            {
                img: img1
            },
            {
                img: img2
            },
            {
                img: img3
            }
        ]
    }
    render() {
        return (
            <section className="about-wrapper">
                <div className="container">
                    <div className="about-grid-wrap">
                        <div className="about-left">
                            <SectionsTitle title="Data driven decisions. Proven outcomes." subtitle="Learn About Us" />
                            <p className="about-left-desc">
                            GovLogic is dedicated to providing tactical and scalable solutions to augment your organizational business development and proposal resources. Our advanced capture intelligence solutions integrated with proposal management services provide a dynamic enterprise capability. We pride ourselves on supporting our clients target, capture, and grow within their respective GovCon markets.
                            </p>
                            {/* <Link to="/about">Read More <FiChevronRight className="icon" /></Link> */}
                            <div className="about-iconbox-grid">
                                {this.aboutstate.supports.map((item, index) => {
                                    return <div className="about-iconbox" key={index}>
                                        <span className="about-iconbox-icon">
                                            {item.icon}
                                        </span>
                                        <h3 className="about-iconbox-title">
                                            {item.title}
                                        </h3>
                                    </div>
                                })}
                            </div>
                            {/* <Progressbar /> */}
                        </div>
                        <div className="about-right">
                            {this.aboutrightimg.images.map((img, index) => {
                                return <img key={index} className={'about_img_' + index} src={img.img} alt={'About Image ' + index} />
                            })}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


