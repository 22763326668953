import React, { Component } from 'react';
import { AiOutlineFileDone } from 'react-icons/ai';
import { FaMapSigns, FaSitemap } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';
import { GiPapers } from "react-icons/gi";
import { RiShieldCheckLine } from 'react-icons/ri';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import SectionsTitle from '../common/SectionsTitle';

export default class ServiceSlider extends Component {
    serviceslidres = {
        items: [
            {
                icon: <FaMapSigns />,
                title: "Executive Strategy",
                desc: "Using our resources and experience, we team with clients to develop the unique high-arching strategy upon which our next steps are based.",
                url : '/capture-intelligence'
            },
            {
                icon: <FaSitemap />,
                title: "Pipeline Development",
                desc: "At GovLogic, we utilize each of our client’s highly-calibrated strategy to identify upcoming contract opportunities 3, 6, or 12 months out, or longer.",
                url : '/capture-intelligence'
            },
            {
                icon: <AiOutlineFileDone />,
                title: "GSA Schedule | IDIQ | GWAC Managment",
                desc: "We find opportunities matching your capabilities and engage teaming partners to develop a cohesive response to RFIs and RFQs.",
                url : '/capture-intelligence'
            },
            {
                icon: <RiShieldCheckLine />,
                title: "Capture Management",
                desc: "Upon thorough research of target opportunitie, our Capture team will help you target, approach, and capture incumbent personnel and key personnel.",
                url : '/capture-intelligence'
            },
            {
                icon: <GiPapers />,
                title: "Proposal Management",
                desc: "We can help you deliver a proposal that address your customer’s pain points, encompasses all of their requirements, and aligns with their mission.",
                url : '/proposal-management'
            }
        ]
    }
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 2
            }
        }
    }
    render() {
        return (
            <section className="service-slider-wrapper">
                <div className="container">
                    <div className="service-slider-grid d-grid">
                        <div className="service-slider-left">
                            <OwlCarousel
                                className="owl-theme"
                                loop={true}
                                autoplay={false}
                                margin={30}
                                dots={true}
                                nav={false}
                                items={2}
                                responsive={this.state.responsive}
                            >
                                {this.serviceslidres.items.map((item, index) => {
                                    return <div className="service-slider-item" key={index}>
                                        <span className="service-slider-icon">{item.icon}</span>
                                        <Link to={item.url}><h3 className="service-slider-title">{item.title}</h3></Link>
                                        <p className="service-slider-desc">{item.desc}</p>
                                        <Link to={item.url} className="simple-button">See Details <FiChevronRight className="icon" /></Link>
                                    </div>
                                })}
                            </OwlCarousel>
                        </div>
                        <div className="service-slider-right">
                            <SectionsTitle title="Drive Results with Market Intelligence." subtitle="A Complete Package" />
                            <p className="desc">
                                By improving your knowledge and understanding of your customer targets, opportunities, market dynamics, and competition, you will be better positioned to win and succeed on government contracts. GovLogic has extensive experience in identifying and researching opportunities and collecting tactical data to help you prepare your bid strategy and develop responsive proposals.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
