import React, { Component } from "react";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import SectionsTitle from "../common/SectionsTitle";
import MailForm from "../other/MailForm";
import ContactSidebar from "./ContactSidebar";

export default class ContactDetails extends Component {
  render() {
    return (
      <section className="contact-form-wrapper">
        <div className="container">
          <div className="contact-form-grid d-grid">
            <div className="left">
              <SectionsTitle title="Ready. Set. Win." />
              <p>
                Government contractors and business owners are inundated with an
                overabundance of what they perceive to be good opportunities to
                grow their business. But not every opportunity is the correct
                one to pursue. Our mission is to help companies identify,
                quality, capture, and pursue the right opportunities.
              </p>
              <p>
                GovLogic offers proven processes and resources to help you meet
                your goals and obtain measurable results. Let’s begin with an
                open discussion
              </p>
              <p>
                If you’d like to find out more about how GovLogic can become
                your integrated service provider, please contact us for a free
                30-minute initial consultation.
              </p>
              <div className="sidebar-social-profile">
                <ul className="contact-form-socials d-flex">
                  <li>
                    <a href="https://linkedin.com">
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com">
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right">
              <MailForm />
            </div>
          </div>

          <div className="contact-form-map-grid d-grid">
            <div className="right">
              <ContactSidebar />
            </div>
          </div>

          <div className="contact-form-subscribe-wrap text-center"></div>
        </div>
      </section>
    );
  }
}
