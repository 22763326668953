
import React, { Component } from 'react';
import { FiChevronRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import About from '../components/about/About';
import About1 from '../components/about/About1';
import Navbar from "../components/common/Navbar";
import SectionsTitle from '../components/common/SectionsTitle';
import Clients from '../components/other/Clients';
import CounterUp from '../components/other/CounterUp';
import Cta from '../components/other/Cta';
import CtaForm from '../components/other/CtaForm';
import Divider from '../components/other/Divider';
import Footer from '../components/other/Footer';
import Service from '../components/services/Services';
import Slider from '../components/sliders/slider';
import SimpleTab from '../components/tabs/SimpleTab';
import Team from '../components/team/Team';
import Testimonial from '../components/team/Testimonial';

export default class Home extends Component {

    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Slider />
                <div className="h-service">
                    <div className="container">
                        <div className="row">
                            <div className="col-8">
                                <SectionsTitle title="Business Development, Capture, and Proposal Management services tailored for the GovCon market." subtitle="Our Dedicated Services" />
                            </div>
                            <div className="col-4">
                                <div className="service-button text-right">
                                    <Link to="/services" className="theme-button">View All Services <FiChevronRight className="icon" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Service />
                </div>
                <Divider />
                <About1 />
                <Divider />
                <About />
                <SimpleTab />
                <CounterUp />
                <section className="testimonial-wrapper ">
                    <div className="text-center">
                        <SectionsTitle title="Say About Us." subtitle="See What Our Customers" />
                    </div>
                    <Testimonial />
                </section>
                <Cta />
                <Team />

                <Clients />

                <CtaForm />
                <Divider />

                <Footer />
            </>
        )
    }
}

