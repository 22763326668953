import React, { Component } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { GiArrowsShield, GiPapers } from "react-icons/gi";
import { Link } from "react-router-dom";

export default class Service extends Component {
  services = {
    items: [
      {
        icon: <GiArrowsShield className="service-items-icon" />,
        title: "Strategic Consulting",
        description:
          "Building a comprehensive business development plan and processes that align with core capabilities and connects with target agency’s mission.",
        url: "/strategy",
      },
      {
        icon: <AiOutlineFileSearch className="service-items-icon" />,
        title: "Capture Intelligence.",
        description:
          "Delivering strategic and tactical capture solutions with a calibrated integration of people, process, and technology.",
        url: "/capture-intelligence",
      },
      {
        icon: <GiPapers className="service-items-icon" />,
        title: "Proposal Management.",
        description:
          "Our experienced proposal management team can augment your internal proposal creative staff, integrate with your team, or manage the entire government proposal process on your behalf.",
        url: "/proposal-management",
      },
      {
        icon: <GiPapers className="service-items-icon" />,
        title: "RAPTOR™",
        description:
          "GovLogic’s patent-pending capture process for maximizing revenue from GSA Schedules, IDIQs, and GWACs.",
        url: "/",
      },
    ],
  };

  render() {
    return (
      <section className="services-area">
        <div className="container">
          <div className="services-items-grid">
            {this.services.items.map((item, index) => {
              return (
                <Link  key={index} to={item.url} className="service-items">
                  <div key={index}>
                    <span className="service-items-iconbox">{item.icon}</span>
                    <h3 className="service-items-title">{item.title}</h3>
                    <p className="service-items-description">
                      {item.description}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}
