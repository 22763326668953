import React, { Component } from 'react';
import { IoIosQuote } from 'react-icons/io';
import OwlCarousel from 'react-owl-carousel';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import img1 from '../../assets/images/headshot-1.jpeg';
import img2 from '../../assets/images/headshot-2.jpeg';
import img3 from '../../assets/images/headshot-3.png';
import img4 from '../../assets/images/headshot-4.png';
import img5 from '../../assets/images/headshot-5.png';
import img6 from '../../assets/images/headshot-6.png';
import img7 from '../../assets/images/headshot-7.jpeg';
import img8 from '../../assets/images/headshot-8.png';


export default class Testimonial extends Component {
    sliders = {
        items: [
            {
                name: "Tanbir A.",
                city: "VP, Business Development",
                img: img1,
                desc: "I have been in the U.S. federal government contracting industry for well over 15 years and I have not worked with a business development consulting group that is on par with GovLogic. Their dedication to clients is unrivaled.",
            },
            {
                name: "Brian W.",
                city: "CEO",
                img: img2,
                desc: "Partnering with GovLogic has been pivotal for our business growth. GovLogic certainly delivers transformational results. We were able to develop a robust capture process and win two new contracts."
            },
            {
                name: "Maya W.",
                city: "Capture Manager",
                img: img3,
                desc: "It is always a pleasure to work with GovLogic to maximize the pWin of the opportunities on our pipeline. With efficient management and clear strategy, GovLogic consistently produces the best outcomes.",
            }
        ]
    }
    images = {
        items: [
            {
                img: img4
            },
            {
                img: img2
            },
            {
                img: img3
            },
            {
                img: img8
            },
            {
                img: img1
            },
            {
                img: img5
            },
            {
                img: img6
            },
            {
                img: img7
            }
        ]
    }
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 2
            }
        }
    }
    render() {
        return (
            <>
                {this.images.items.map((item, index) => {
                    return <img key={index} className="shape_img" src={item.img} alt="Testimonial" />
                })}
                <div className="container">
                    <OwlCarousel
                        className="owl-theme"
                        loop={true}
                        autoplay={true}
                        margin={10}
                        dots={true}
                        nav={false}
                        responsiveClass={true}
                        responsive={this.state.responsive}
                    >
                        {this.sliders.items.map((item, index) => {

                            return <div className="testimonial-items" key={index}>
                                <p className="desc">{item.desc}<IoIosQuote className="quote_icon" /></p>
                                <div className="testimonial-bottom-flex">
                                    <img src={item.img} alt="Testimonial" />
                                    <div className="designation">
                                        <h3 className="name">{item.name}</h3>
                                        <p className="city">{item.city}</p>
                   
                                    </div>
                                </div>
                            </div>
                        })}

                    </OwlCarousel>
                </div>
            </>
        )
    }
}
