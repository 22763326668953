import React, { Component } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';

export default class owlcarousel extends Component {
    sliders = {
        items: [
            {
                title: 'Capture Intelligence',
                description: 'Delivering strategic and tactical capture solutions with a calibrated integration of people, process, and technology.',
                url: '/capture-intelligence',
                bg: 'bg-1',
                col: '8'
            },
            {
                title: 'Strategic Consulting',
                description:'Building a comprehensive executive strategy and processes that align with core capabilities and connects with target agency’s mission.',
                url: '/proposal-management',
                bg: 'bg-3',
                col: '8 offset-2 text-center'
            },
            {
                title: 'Proposal Management',
                description: 'Our experienced proposal management team can augment your internal proposal creative staff, integrate with your team, or manage the entire government proposal process on your behalf.',
                url: '/digital-services',
                bg: 'bg-2',
                col: '8'
            }
        ]
    }
    render() {
        return (
            <section className="hero-slider-wrapper">
                <OwlCarousel className="owl-theme" loop={true} autoplay={true} margin={0} dots={false} nav items={1} >
                    {this.sliders.items.map((item, index) => {
                        return <div key={index} className={'hero-slider-item ' + item.bg}>
                            <div className="container">
                                <div className="row">
                                    <div className={'col-' + item.col}>
                                        <div className="hero-slider-content">
                                            <h1>{item.title}</h1>
                                            <p>{item.description}</p>
                                            <div className="hero-slider-btns">
                                                <Link to={item.url} className="theme-button">Learn More<FiChevronRight className="icon" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </OwlCarousel>
            </section >
        )
    }
}
