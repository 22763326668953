import "chart.js";
import React, { Component } from "react";
import { FiCheckCircle } from "react-icons/fi";
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/owl.carousel.css";
import "../../assets/css/owl.theme.default.min.css";
import img5 from "../../assets/images/capture.jpeg";
import img4 from "../../assets/images/contract-1.jpeg";
import img3 from "../../assets/images/planning-1.jpeg";
import img1 from "../../assets/images/strategy-1.jpeg";
import img2 from "../../assets/images/strategy-2.jpeg";

export default class CaptureDetailsContent extends Component {
  render() {
    return (
      <div className="service-content-wrap">
        <OwlCarousel
          className="owl-theme service-content-details-slider"
          loop={true}
          autoplay={true}
          margin={10}
          dots={true}
          nav={false}
          items={1}
        >
          <div className="item">
            <img src={img1} alt="Service Details Slider" />
          </div>
          <div className="item">
            <img src={img2} alt="Service Details Slider" />
          </div>
          <div className="item">
            <img src={img3} alt="Service Details Slider" />
          </div>
          <div className="item">
            <img src={img4} alt="Service Details Slider" />
          </div>
          <div className="item">
            <img src={img5} alt="Service Details Slider" />
          </div>
        </OwlCarousel>
        <div className="service-content">
          <h1>Capture - Done Intelligently.</h1>
          <p>
            By improving your knowledge and understanding of your customer
            targets, opportunities, market dynamics, and competition, you will
            be better positioned to win and succeed on government contracts.
          </p>
          <p>
            GovLogic has extensive experience in identifying and researching
            opportunities and collecting tactical data to help you prepare your
            bid strategy and develop responsive proposals, utilizing:
          </p>
          <div className="service-content-mdl-grid d-grid">
            <ul>
              <li>
                <FiCheckCircle className="icon" /> Executive Strategy
              </li>
              <li>
                <FiCheckCircle className="icon" /> Pipeline Development
              </li>
              <li>
                <FiCheckCircle className="icon" /> GSA Schedule | IDIQ | GWAC
                Management
              </li>
              <li>
                <FiCheckCircle className="icon" /> Capture Management
              </li>
            </ul>
            <div className="service-content-mdl-img">
              <img src={img1} alt="Service Details" />
            </div>
          </div>
          <h1>Our Process.</h1>
          <div className="service-content-planning-grid d-grid">
            <div className="planning-img">
              <img src={img2} alt="Planning" />
            </div>
            <div className="planning-lists">
              <h3>Executive Strategy</h3>
              <p>
                To create a winning strategy, you must understand who you are,
                your mission, the services you offer, who your target customers
                are, what resources you have available, and how to take all of
                this and utilize it to succeed in your target market base. Using
                our resources and experience, we team with clients to develop
                the unique high-arching strategy upon which our next steps are
                based.
              </p>
              <ul>
                <li>
                  <FiCheckCircle className="icon" /> Strategy Development
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Corporate Gap Analysis
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Resource Alignment
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Gate Review Process
                  Development
                </li>
              </ul>
            </div>
          </div>

          <div className="service-content-planning-grid d-grid">
            <div className="planning-img">
              <img src={img3} alt="Pipeline Development" />
            </div>
            <div className="planning-lists">
              <h3>Pipeline Development</h3>
              <p>
                Utilizing our resource network, GovLogic utilizes each client’s
                highly-calibrated strategy to identify upcoming contract
                opportunities 3, 6, or 12 months out, or longer. This develops a
                progressive pipeline of opportunities that range from small
                single award opportunities to large strategic IDIQs and GWACs.
              </p>
              <ul>
                <li>
                  <FiCheckCircle className="icon" /> Opportunity Identification
                  and Qualification
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Pursuit Strategy
                  Development
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Market Research Response
                  Support
                </li>
              </ul>
            </div>
          </div>

          <div className="service-content-planning-grid d-grid">
            <div className="planning-img">
              <img src={img4} alt="Contract Management" />
            </div>
            <div className="planning-lists">
              <h3>Contract Management</h3>
              <p>
                Companies are often inundated with potential opportunities from
                open source portals, GSA Schedules, IDIQs, and GWACs. We
                understand that you may not have the resources to effectively
                review each opportunity and we are here to help. Our expert
                analysts will monitor and review opportunities that are posted
                on your GSA Schedules, IDIQs, and GWACs. We will cross refence
                these opportunities against your core capabilities and engage
                your teaming partners to develop a cohesive response to RFIs and
                RFQs.
              </p>
              <ul>
                <li>
                  <FiCheckCircle className="icon" /> GSA Schedules
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Multiple Award IDIQs
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Governmentwide Acquisition
                  Contracts (GWACs)
                </li>
              </ul>
            </div>
          </div>

          <div className="service-content-planning-grid d-grid">
            <div className="planning-img">
              <img src={img5} alt="Planning" />
            </div>
            <div className="planning-lists">
              <h3>Capture Management</h3>
              <p>
                Building a knowledge base around the details of target
                opportunities better positions you to win the contract. GovLogic
                researches each opportunity to learn its history, uncover major
                competitors, and match your company’s resources to determine
                what specific support services will be required to capture and
                win the contract. Our Workforce Solutions Team will help you
                target, approach, and capture incumbent personnel and key
                personnel.
              </p>
              <ul>
                <li>
                  <FiCheckCircle className="icon" /> Capture Plan Development
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Competition Assessment & Analysis
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Winning Team Development
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Capture Incumbent Personnel
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
