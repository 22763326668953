import React, { Component } from 'react'
import CountUp from 'react-countup'
import { AiOutlineCheckCircle, AiOutlineNotification, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { FaHandshake } from 'react-icons/fa'
import SectionsTitle from '../common/SectionsTitle'


export default class CounterUp extends Component {

    counterstates = {
        items: [
            {
                icon: <AiOutlineNotification className="icon" />,
                number: <CountUp start={0}
                    end={300}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix=" "
                    suffix=" "
                />,
                numsuffix: '+',
                title: 'Finished Projects'
            },
            {
                icon: <AiOutlineUsergroupAdd className="icon" />,
                number: <CountUp start={0}
                    end={20}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix=" "
                    suffix=" "
                />,
                numsuffix: '+',
                title: 'Team Members'
            },
            {
                icon: <FaHandshake className="icon" />,
                number: <CountUp start={0}
                    end={30}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix=" "
                    suffix=" "
                />,
                numsuffix: '+',
                title: 'Satisfied Clients'
            },
            {
                icon: <AiOutlineCheckCircle className="icon" />,
                number: <CountUp start={0}
                    end={500}
                    duration={8}
                    separator=" "
                    decimal=","
                    prefix="$"
                    suffix=" "
                />,
                numsuffix: 'M+',
                title: 'Contracts Awarded'
            }
        ]
    }
    render() {
        return (
            <>
                <section className="counterup-wrapper text-center">
                    <div className="container">
                        <SectionsTitle title="Numbers Speak." subtitle="See What Our" />
                        <div className="counterup-grid-wrap">

                            {this.counterstates.items.map((item, index) => {
                                return <div className="counterup-items" key={index}>
                                    <div className="counterup-iconbox">
                                        {item.icon}
                                    </div>
                                    <h1 className="counterup-number">{item.number}{item.numsuffix}</h1>
                                    <p className="counterup-title">{item.title}</p>
                                </div>
                            })}

                        </div>
                    </div>
                </section>
            </>
        )
    }
}
