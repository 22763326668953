import React, { Component } from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiPhoneCall } from 'react-icons/fi'
import { MdMailOutline } from 'react-icons/md'
import emailjs from 'emailjs-com';
import { toast } from 'react-toast';
import MailForm from './MailForm';

const TEMPLATE_ID = 'template_8zb6wlg';
const SERVICE_ID = 'service_50nkbaw';
const USER_ID = 'user_i9mC2OmtT18VDpzMvlLTh';

export default class CtaForm extends Component {

    constructor() {
        super()
        this.state = {
            from_name: '',
            from_email: '',
            subject: '',
            message: ''
        }
    }

    handleInputChange(event) {
        event.persist();
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({ [name]: value });
    }

    validateMail(){
        const { from_name, from_email, subject, message } = this.state;
        return (from_name && from_email && subject && message) ? true : false;
    }

    sendMessage(event) {
        event.preventDefault();
        console.log('SEND EVENT', event)
        if (!this.validateMail()) return;
        const { from_name, from_email, subject, message } = this.state;
        const templateParams = {from_name, from_email, subject, message };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
          .then(
            function(response) {
              toast.success("Your message has successfully sent!");
              console.log("SUCCESS!", response.status, response.text);
            },
            function(err) {
              toast.error("Your message was not able to be sent");
            }
          );
        this.setState({ from_name: '', from_email: '', subject: '', message: '' });
      }


    render() {
        return (
            <section className="ctaform-wrapper">
                <div className="container">
                    <div className="ctaform-grid">
                        <div className="ctaform-left">
                            <SectionsTitle title="Get Free Consultation." subtitle="Ready. Set. Win." />
                            <div className="ctaform-contact-info-grid text-center">
                                <div className="item">
                                    <FiPhoneCall className="icon" />
                                    <h2 className="item-title">Phone</h2>
                                    <p>(703) 434 - 9980</p>
                                </div>
                                <div className="item">
                                    <MdMailOutline className="icon" />
                                    <h2 className="item-title">Email</h2>
                                    <p>info@gov-logic.com</p>
                                </div>
                            </div>
                        </div>
                        <MailForm/>
                    </div>
                </div>
            </section>
        )
    }
}
