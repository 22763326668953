import React, { Component } from "react";
import { FiCheckCircle } from "react-icons/fi";
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/owl.carousel.css";
import "../../assets/css/owl.theme.default.min.css";
import img1 from "../../assets/images/services-2.jpeg";

export default class ProposalManagementContent extends Component {
  render() {
    return (
      <div className="service-content-wrap">
        <OwlCarousel
          className="owl-theme service-content-details-slider"
          loop={false}
          autoplay={false}
          margin={10}
          dots={false}
          nav={false}
          items={1}
        >
          <div className="item">
            <img src={img1} alt="Service Details Slider" />
          </div>
        </OwlCarousel>
        <div className="service-content">
          <h1>Proposal Management</h1>
          <p>
            Creating a winning proposal is a classic project management
            challenge where there are always constraints on time, cost, and
            scope. Without the correct alignment of resources and a robust
            proposal development process, delivering a high-quality proposal is
            nearly impossible.
          </p>
          <p>
            At GovLogic, we can help you deliver a proposal that address your
            customer’s pain points, encompasses all their requirements, aligns
            with their mission, and establishes how your company will help them
            meet their goals.
          </p>
          <p>
            GovLogic’s experienced proposal management team can augment your
            internal proposal creative staff, integrate with your team, or
            manage the entire government proposal process on your behalf. You
            decide how you need us to work with you.
          </p>
            <p>Our Proposal Management services include:</p>
          <div className="service-content-mdl-grid d-grid">
            <ul>
              <li>
                <FiCheckCircle className="icon" /> Proposal Management
              </li>
              <li>
                <FiCheckCircle className="icon" /> Proposal Coordination
              </li>
              <li>
                <FiCheckCircle className="icon" /> Technical Writing
              </li>
              <li>
                <FiCheckCircle className="icon" /> Proposal Graphics
                look
              </li>
              <li>
                <FiCheckCircle className="icon" /> Pricing/Bid Strategy
              </li>
              
            </ul>
            <div className="service-content-mdl-img">
            <ul>
              <li>
                <FiCheckCircle className="icon" /> Ensuring Full Compliance
              </li>
              <li>
                <FiCheckCircle className="icon" /> Color Review Teams
              </li>
              <li>
                <FiCheckCircle className="icon" /> Rapid Task Order Response Team (RAPTOR)
              </li>
              <li>
                <FiCheckCircle className="icon" /> Proposal Mentoring
              </li>
              <li>
                <FiCheckCircle className="icon" /> Orals Coaching
              </li>
              
            </ul>
            </div>
          </div>
          

        </div>
      </div>
    );
  }
}
