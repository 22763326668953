import React from "react";
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toast';
import "./App.css";
import "./assets/css/style.scss";
import About_Page from './pages/About_Page';
import CaptureDetailsPage from './pages/CaptureDetailsPage';
import ContactPage from './pages/ContactPage';
import DigitalServicesDetailsPage from "./pages/DigitalServicesDetailsPage";
import Error from './pages/Error';
import Home from './pages/Home';
import ProposalManagementDetailsPage from './pages/ProposalManagementDetailsPage';
import ServicePage from './pages/ServicePage';

function App() {
  return (
    <React.Fragment>
      <Route>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About_Page} />
          <Route path="/services" component={ServicePage} />
          <Route path="/capture-intelligence" component={CaptureDetailsPage} />
          <Route path="/proposal-management" component={ProposalManagementDetailsPage} />
          <Route path="/strategy" component={DigitalServicesDetailsPage} />
          <Route path="/contact" component={ContactPage} />
          <Route component={Error} />
        </Switch>
      </Route>
      <ToastContainer className="toaster" delay={3000}/>
    </React.Fragment>
  );
}

export default App;
