import React, { Component } from "react";
import {
    FaEnvelope, FaInstagram,
    FaLinkedinIn, FaMapMarkerAlt
} from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";
import FooterLogo from "../../assets/images/logo/govlogic-white.svg";
import ScrollTopBtn from "../layouts/ScrollTopBtn";
import MailForm from "./MailForm";

export default class Footer extends Component {
  render() {
    return (
      <>
        <ScrollTopBtn />
        <footer className="footer-wrapper">
          <div className="container">
            <div className="footer-widgets-grid">
              <div className="footer-widget footer-left">
                <Link to="/">
                  <img src={FooterLogo} alt="Footer Logo" width={200} style={{marginLeft: "-10px"}} />
                </Link>
                {/* <p className="desc">
                                From executive strategy to empowering key aspects of your organization, GovLogic's proven processes lead to success when it comes to succeeding within the federal market. Let our experience and exceptional service work for you.
                            </p> */}
                <ul className="footer-address">
                  <li>
                    <a href="tel:+17034349980">
                      <span className="icon">
                        <FiPhone />
                      </span>
                      (703) 434 - 9980
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@gov-logic.com">
                      <span className="icon">
                        <FaEnvelope />
                      </span>{" "}
                      info@gov-logic.com
                    </a>
                  </li>
                  <li>
                    <a href="https://maps.google.com">
                      <span className="icon">
                        <FaMapMarkerAlt />
                      </span>
                      2600 Crystal Drive <br />
                      Arlington, VA 22202
                    </a>
                  </li>
                </ul>
                <div className="social">
                  <h3>Follow Us</h3>
                  <ul className="footer-social-connect">
                    <li>
                      <a href="https://www.linkedin.com/company/govlogic/about/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-widget footer-help-links">
                <h2>Sitemap</h2>
                <ul className="help-links">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="footer-widget footer-get-in-touch">
                <h2>Get In Touch.</h2>
                <MailForm />
              </div>
            </div>
            <div className="copyright-wrap text-center">
              <p>
                Copyright &copy; {new Date().getFullYear()} GovLogic, LLC. All
                Rights Reserved.
              </p>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
