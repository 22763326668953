import "chart.js";
import React, { Component } from "react";
import { FiCheckCircle } from "react-icons/fi";
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/owl.carousel.css";
import "../../assets/css/owl.theme.default.min.css";
import img1 from "../../assets/images/digital-1.jpeg";

export default class ProposalManagementContent extends Component {
  render() {
    return (
      <div className="service-content-wrap">
        <OwlCarousel
          className="owl-theme service-content-details-slider"
          loop={false}
          autoplay={false}
          margin={10}
          dots={false}
          nav={false}
          items={1}
        >
          <div className="item">
            <img src={img1} alt="Service Details Slider" />
          </div>
        </OwlCarousel>
        <div className="service-content">
          <h1>Build Professionalism with a Cohesive Digital Image</h1>
          <p>
            GovLogic delivers business process development and executive
            coaching targeted towards the enterprise business development and
            capture management processes.
          </p>
          <p>
            Our methods are derived from industry best practices and Shipley
            based training. GovLogic will review and perform a baseline
            assessment of your company’s capabilities, past performance, and
            business development related processes.
          </p>
          <div className="service-content-mdl-grid d-grid">
            <ul>
              <li>
                <FiCheckCircle className="icon" /> Strategic Planning
              </li>
              <li>
                <FiCheckCircle className="icon" /> Corporate Gap Analysis
              </li>
            </ul>
            <div className="service-content-mdl-img">
              <ul>
                <li>
                  <FiCheckCircle className="icon" /> Capture Process Development
                </li>
                <li>
                  <FiCheckCircle className="icon" /> Agile Gate Reviews
                </li>
              </ul>
            </div>
          </div>
          <h1>SBA Mentor - Protege Program Services</h1>
          <p>
            <strong>
              Is your company experiencing rapid growth or looking to leverage
              it’s small business socio-economic advantages by partnering with a
              larger company?
            </strong> <br/>
            GovLogic is here to support you with finding a perfect partner that
            will help take your company to the next level.
          </p>
        </div>
      </div>
    );
  }
}
