import React, { Component } from "react";
import clients from "../../assets/images/clients-1.jpeg";
import meeting from "../../assets/images/meeting-4.jpeg";

export default class About1 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal2 = this.openModal2.bind(this);
  }

  openModal2() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <section className="about-st1-wrapper">
        <div className="container">
          <div className="about-st1-grid">
            <div className="about-left">
              <h1 className="about-left-title">
                Optimal business development solutions to <strong>accelerate</strong> your company's growth.
              </h1>
              <p className="about-left-desc">Every company is unique and has evolving business needs to support their growth. We offer customized service packages based on your existing resources, budget, and required support level.</p>
            </div>
            <div className="about-right">
              <img
                className="about-right-img"
                src={clients}
                alt="About Video"
              />
            </div>
          </div>
          <div className="history-area">
            <div className="row">
              <div className="col-8">
                <img className="history_img" src={meeting} alt="History" />
              </div>
              <div className="history-details-box">
                <h1 className="history-details-title">
                  More than <br />
                  <strong>1200 opportunity engagements</strong> completed.
                </h1>
                <p className="history-details-desc">
                  Whether you are looking to pursue a single award contract,
                  IDIQ, or multiple award GWAC, our team is ready to support
                  you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
