import emailjs from "emailjs-com";
import React, { Component } from "react";
import { toast } from "react-toast";

const TEMPLATE_ID = "template_0husvgo";
const SERVICE_ID =  "service_klf8bmm";
const PUBLIC_KEY = "MtuVFSjY0_pDxp7a7";

export default class MailForm extends Component {
  constructor() {
    super();
    this.state = {
      from_name: "",
      from_email: "",
      message: "",
    };
  }

  handleInputChange(event) {
    event.persist();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  validateMail() {
    const { from_name, from_email, message } = this.state;
    return from_name && from_email && message ? true : false;
  }

  sendMessage(event) {
    event.preventDefault();
    if (!this.validateMail()) return;
    const { from_name, from_email, message } = this.state;
    const templateParams = { from_name, from_email, message };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY).then(
      function (response) {
        toast.success("Your message has successfully sent!");
      },
      function (err) {
        toast.error("Your message was not able to be sent");
      }
    );
    this.setState({ from_name: "", from_email: "", message: "" });
  }

  render() {
    return (
      <div className="container">
        <div className="ctaform-grid">
          <div className="ctaform-right">
            <form>
              <div className="form-grid">
                <input
                  required
                  type="text"
                  name="from_name"
                  placeholder="Name"
                  value={this.state.from_name}
                  onChange={this.handleInputChange.bind(this)}
                />
                <input
                  type="email"
                  name="from_email"
                  placeholder="Email"
                  value={this.state.from_email}
                  onChange={this.handleInputChange.bind(this)}
                />
              </div>
              <textarea
                id="textarea"
                name="message"
                placeholder="Message"
                value={this.state.message}
                onChange={this.handleInputChange.bind(this)}
              ></textarea>
              <button
                type="button"
                value="Send Message"
                className="theme-button"
                onClick={this.sendMessage.bind(this)}
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
