import React, { Component } from "react";
import { Link } from "react-router-dom";
import error from "../../assets/images/404-img3.gif";

export default class ErrorContent extends Component {
  render() {
    return (
      <section className="error-content-wrapper text-center">
        <div className="error-content-width">
          <img src={error} alt="Error" />

          <h1>Oops! Page not found.</h1>
          <p>
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </p>
          <Link className="theme-button" to="/">
            Back To Home
          </Link>
        </div>
      </section>
    );
  }
}
